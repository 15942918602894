import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container as BlockContainer, Features } from '../components/Blocks'
import { Hero, BecomeMember } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

const IndexPage = () => (
  <Layout>
    <SEO title="Programs" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/Koopinoy Banner.jpg' height={600} x={40} />
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">IMPACTING MEMBERS’ LIVES THROUGH OUR FINANCIAL PROGRAMS</h1>
              <div className="text-justify">
                <p>KooPinoy SIDC Savings and Loans wanted to change the structure of its members finances especially if they are about to engage into business, because we believe that through our extended financial help, members can have lower-cost, more profitable businesses.</p>
                <p>KooPinoy SIDC Savings and Loans provides financial inclusion and members’ easy access to financial services.  Our framework considered members as financially included as they have access to affordable financial services that are tailored fit to their financial needs.</p>
              </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">KooPinoy SIDC Savings and Loans Program Features</h1>
          </Col>
        </Row>
        <Row>
		{/*<Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="HIGH PATRONAGE REFUND">The cooperative way of sharing profits among  members based on their interest paid for the year.</Features>
		</Col>*/}
		  <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="HIGH PATRONAGE REFUND">The cooperative way of sharing profits among  members based on their interest paid for the year.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="HIGH YIELD RETURN OF INTEREST">There is 1% interest rate on regular savings deposit. Likewise, Time deposits were ranging from 1% to 4.5% per annum. On a long term pooling of funds, we can provide a 5.5% interest per annum.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="LOYALTY REWARD PROGRAM">Being a good member you will enjoy a discount on interest, an easy and hassle free processing and a higher appraised value on collateralized loan.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="MORTUARY BENEFIT">A cash assistance for the burial services of a member with a good standing record. (for regular members only)</Features>
          </Col>
          <Col lg="4" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="SCHOLARSHIP GRANTS">Financial assistance given to deserving children of members with good standing.</Features>
          </Col>
          <Col lg="4" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="FINANCIAL LITERACY">Members-customers can attend free seminar in various schedules.</Features>
          </Col>
          <Col lg="4" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="YEARLY INCENTIVE">Get additional incentive based on paid interest in a year.</Features>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={5} center={false}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3 text-center">KooPinoy Products</h1>
          </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg={{size: 6, order: 1}} sm={{size: 12, order: 2}} className="p-3">
              <div className="lead">
                <h3><b>Deposits</b></h3>
                <div className="text-justify">
                  <p>Save money and watch them grow with your preferred Savings product. With KooPinoy Savings, you are at ease because it is safe and secure.</p>
                </div>
                <ol>
                  <li>Regular Deposit</li>
                  <li>Time Deposit</li>
                  <li>myFuture Savings Fund</li>
                  <li>Young Savers Club</li>
                </ol>
              </div>
            </Col>
            <Col lg={{size: 6, order: 2}} sm={{size: 12, order: 1}} className="p-0 text-center">
              <Hero src="app/Deposits.jpg" height={400} />
              <Link className="btn btn-primary m-3" to="/inquire">Inquire</Link>
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg={{size: 6, order: 1}} sm={{size: 12, order: 1}} className="p-0 text-center">
              <Hero src="app/loans.png" height={400} />
              <Link className="btn btn-primary m-3" to="/inquire">Inquire</Link>
            </Col>
            <Col lg={{size: 6, order: 2}} sm={{size: 12, order: 2}} className="p-3">
              <div className="lead">
                <h3><b>Loans</b></h3>
                <div className="text-justify">
                  <p>KooPinoy extends loan to members with a competitive interest rate and we offer a wider ange of custom-made financial services.</p>
                </div>
                <b>Non Collateralized loan</b>
                <ul>
                  <li>ECL Multi Purpose Loan</li>
                  <li>Member's Easy Emergency Loan</li>
                  <li>Micro and Small Enterprise Loan</li>
                  <li>Institutional Loan</li>
                  <li>Agricultural Production Loan</li>
                </ul>
                <b>Collateralized Loan</b>
                <ul>
                  <li>Cash Loan</li>
                  <li>Vehicle Loan</li>
                  <li>Appliance Loan</li>
                  <li>Memorial Loan</li>
                  <li>Special Loan</li>
                  <li>SIDC Coop Pabahay Loan</li>
                  <li>Construction Loan</li>
                  <li>SIDC Expanded Credit Loan</li>
                  <li>AgriFarm Equipment Loan</li>
                  <li>Condominium Loan</li>
                  <li>Solar Power Financing Program Loan</li>
                </ul>
              </div>
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg={{size: 6, order: 1}} sm={{size: 12, order: 2}} className="p-3">
              <div className="lead">
                <h3><b>Insurance</b></h3>
                <div className="text-justify">
                  <p>Secure your future. Be Protected. Insured Now.</p>
                </div>
                <b>Life Insurance</b>
                <ol>
                  <li>Group Yearly Renewable</li>
                  <li>Coop Loan Protection Plan</li>
                </ol>
                <b>Non-Life Insurance</b>
                <ol>
                  <li>Comprehensive Insurance</li>
                  <li>Fire Insurance</li>
                  <li>Coop Aksi Plan</li>
                  <li>Student Group Accident Insurance</li>
                  <li>Third Party Liability Insurance (TPL)</li>
                </ol>
              </div>
            </Col>
            <Col lg={{size: 6, order: 2}} sm={{size: 12, order: 1}} className="p-0 text-center">
              <Hero src="app/Koopinoy_Logo_Square.jpg" height={400} className="border" />
              <Link className="btn btn-primary m-3" to="/inquire">Inquire</Link>
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg={{size: 6, order: 1}} sm={{size: 12, order: 1}} className="p-0 text-center">
              <Hero src="app/Investment-2.jpg" height={350} className="border" />
              <Hero src="app/Investment.jpg" height={350} className="border" />
              <Link className="btn btn-primary m-3" to="/inquire">Inquire</Link>
            </Col>
            <Col lg={{size: 6, order: 2}} sm={{size: 12, order: 2}} className="p-3">
            <div className="lead">
                <h3><b>Investment</b></h3>
                <ul>
                  <li>Layer Farm Investment</li>
                  <li>777 Retailing Store Investment </li>
                </ul>
              </div>
            </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">4 Easy Steps on Becoming a Member at SIDC</h1>
              <p className="lead">Please check the below requirements and request your individal offer.  We are happy to serve and send informations.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
              <Features iconClass="" title="1">Attend Pre-Membership Education Seminar (PMES)</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="" title="2">Accomplished SIDC Regular or Associate membership application form</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="" title="3">Submit necessary requirements</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="" title="4">You're now a Member and you can avail our services: Savings and Loans.</Features>
          </Col>
        </Row>
        <Row>
          <Col md="12">
              <a className="btn btn-primary" href={process.env.GATSBY_SITE_MAIN + '/membership'}>Become a Member</a>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BecomeMember />
  </Layout>
)

export default IndexPage
